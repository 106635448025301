.reminder-group {
	display: flex;
	justify-content: space-between;
}

.reminder-group input,
.reminder-group select {
	width: 48%;
}

.reminder-group select {
	height: 34px;
}

.container-fluid .row + .row {
	margin-top: 15px;
}
